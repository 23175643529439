import { Fragment, useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  EditOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  // UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Layout,
  Input,
  Form,
  Upload,
  notification,
  Table,
  Space,
  Popconfirm,
  // Checkbox,
  // Spin,
  Tag,
  InputNumber,
} from "antd";
import {
  DataTypeColor,
  DataGenerateChange,
  DataTypeStyle,
} from "../../../types/styleColor";
import imageDefault from "../../../helper/imageDefault";
import { useDispatch, useSelector } from "react-redux";
import { UseConvertImage } from "../../../hooks/useConvertImage";
import {
  getItemById,
  getStyleById,
} from "../../../redux/services/styleColorSlice";

import {
  getText,
  addText,
  addFont,
  addColor,
  addRowText,
  addRowFont,
  addRowColor,
  editText,
  editFont,
  editColor,
  setRowTextEdit,
  setRowFontEdit,
  setRowColorEdit,
  changeTextStatus,
  changeFontStatus,
  changeColorStatus,
  cancelText,
  cancelFont,
  cancelColor,
  deleteRowText,
  deleteRowFont,
  deleteRowColor,
  // handleCheckboxStatus,
  setDataStyleColor,
} from "../../../redux/services/textStyleSlice";

import { styleColorApi } from "../../../api/styleColorApi/styleColorApi";
import { Face } from "../../../types/faces";
import { facesApi } from "../../../api";
import overLoader from "../../../components/overLoader";

export const TextStyleColor = () => {
  const dispatch = useDispatch();
  const { item_id, style_id } = useParams();
  const { Content } = Layout;
  const [api, showPopup] = notification.useNotification();
  const [itemGenerateChange, setItemGenerateChange] = useState<
    DataGenerateChange[]
  >([]);
  const [colorChange, setColorChange] = useState("");
  const [formText] = Form.useForm();
  const [formFont] = Form.useForm();
  const [formColor] = Form.useForm();
  const [faces, setFaces] = useState<any>([]);
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [activeFace, setActiveFace] = useState<number>(faces[0]?.face);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
    </div>
  );

  // const valueFile = (e: any) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e.fileList;
  // };

  const { itemById, dataStyleById } = useSelector(
    (state: any) => state.styleColorReducer
  );

  const {
    idRowTextEdit,
    idRowFontEdit,
    idRowColorEdit,
    dataText,
    dataFont,
    dataColor,
    textStatus,
    fontStatus,
    colorStatus,
    loading,
    dataStyleColor,
    listUpdateStyleColor,
  } = useSelector((state: any) => state.textStyleReducer);

  // TEXT
  const onFinishText = async (values: any) => {
    const newText = {
      name: values.name,
      price: values.price,
      item_id: item_id,
      parent_id: style_id,
      is_text_style: 1,
      length_limit: values.length_limit,
      image_base64: imageDefault,
    };

    try {
      if (textStatus === "add") {
        const res = await dispatch(addText({ ...newText }));

        if (res.payload.status === "success") {
          dispatch(cancelText("add"));

          const newData = {
            name: res?.payload?.data?.name,
            item_id: item_id,
            parent_id: style_id,
            image_base64: res?.payload?.data?.image,
            price: res?.payload?.data?.price,
            is_text_style: 1,
            length_limit: res?.payload?.data?.length_limit,
            key: res?.payload?.data?.style_id,
          };

          dispatch(addRowText(newData));
          dispatch(getText(style_id));
          alertSuccess("追加しました。");
          formText.resetFields();
        }
      } else {
        const res = await dispatch(
          editText({
            id: idRowTextEdit,
            body: { ...newText },
          })
        );

        if (res.payload.status === "success") {
          alertSuccess("追加しました。");
          dispatch(getText(style_id));
          formText.resetFields();
        }
      }
    } catch (err) {
      alertFail("追加に失敗しました。");
    }
  };
  const handleAddRowText = async () => {
    const rowsInput: any = {
      key: (dataText?.length + 1).toString(),
      image_base64: imageDefault,
      name: "",
      price: 0,
      active: true,
      parent_id: style_id,
    };

    dispatch(addRowText(rowsInput));
    dispatch(changeTextStatus("add"));
  };
  const handleDeleteRowText = async (idDelete: any) => {
    try {
      const res = await dispatch(deleteRowText(idDelete));
      if (res.payload !== undefined) {
        dispatch(getText(style_id));
        alertSuccess("削除しました。");
        // formText.resetFields();
      }
    } catch (err) {
      alertFail("削除に失敗しました。");
    }
  };

  // FONT
  const onFinishFont = async (values: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const handleBase64 = await useConvertImage(values.image_base64?.[0].originFileObj);
    // const newFont = handleBase64
    //   ? {
    //       name: values.name,
    //       image_base64: handleBase64,
    //     }
    //   : {
    //       name: values.name,
    //     };
    const newFont = {
      name: values.name,
    };

    try {
      if (fontStatus === "add") {
        const res = await dispatch(addFont({ ...newFont }));
        if (res.payload.status === "success") {
          dispatch(cancelFont("add"));

          const newData = {
            key: res?.payload?.data?.id,
            name: res?.payload?.data?.name,
            // image_base64: res?.payload?.data?.image,
          };

          dispatch(addRowFont(newData));
          dispatch(getText(style_id));
          formFont.resetFields();
          alertSuccess("追加しました。");
        }
      } else {
        const res = await dispatch(
          editFont({
            id: idRowFontEdit,
            body: { ...newFont },
          })
        );
        if (res.payload.status === "success") {
          formFont.resetFields();
          alertSuccess("追加しました。");
          dispatch(getText(style_id));
        }
      }
    } catch (err) {
      alertFail("追加に失敗しました。");
    }
  };
  const handleAddRowFont = async () => {
    const rowsInput: any = {
      key: (dataFont?.length + 1).toString(),
      image_base64: imageDefault,
      name: "",
      active: true,
    };

    dispatch(addRowFont(rowsInput));
    dispatch(changeFontStatus("add"));
  };
  const handleDeleteRowFont = async (idDelete: any) => {
    try {
      const res = await dispatch(deleteRowFont(idDelete));
      if (res.payload !== undefined) {
        dispatch(getText(style_id));
        alertSuccess("削除しました。");
        formText.resetFields();
      }
    } catch (err) {
      alertFail("削除に失敗しました。");
    }
  };

  // COLOR
  const onFinishColor = async (values: any) => {
    const dataNew = {
      name: values.name,
      color_code: values.color_code,
      style_id: style_id,
    };

    try {
      if (colorStatus === "add") {
        const res = await dispatch(addColor(dataNew));

        if (res.payload.status === "success") {
          dispatch(cancelColor("add"));
          dispatch(addRowColor(dataNew));
          dispatch(getText(style_id));
          alertSuccess("追加しました。");
        }
        formColor.resetFields();
      } else {
        const res = await dispatch(
          editColor({ id: idRowColorEdit, body: dataNew })
        );
        if (res.payload.status === "success") {
          dispatch(getText(style_id));
          formColor.resetFields();
          alertSuccess("追加しました。");
        }
      }
    } catch (err) {
      alertFail("追加に失敗しました。");
    }
  };
  const addRowColors = async () => {
    const rowsInput: any = {
      key: style_id?.toString(),
      name: "Please input data!",
      color_code: "#ffffff",
      active: true,
    };
    dispatch(addRowColor(rowsInput));
    dispatch(changeColorStatus("add"));
  };
  const deleteRowColors = async (idDelete: any) => {
    try {
      const res = await dispatch(deleteRowColor(idDelete));
      if (res.payload !== undefined) {
        alertSuccess("削除しました。");
        dispatch(getText(style_id));
        dispatch(getItemById(item_id));
      }
    } catch (err) {
      alertFail("削除に失敗しました。");
    }
  };

  // ===============

  const getColumnSearchProps = (dataIndex: string) => ({
    render: (text: any, record: any, index: any) => {
      // TEXT
      // if (dataIndex === 'image') {
      //   const imageEdit = (
      //     <img src={record.image || record.image_base64} width={40} style={{ margin: '0 auto' }} alt="Error" />
      //   );
      //   if (record.active) {
      //     return (
      //       <Form.Item name="image_base64" valuePropName="fileList" getValueFromEvent={valueFile}>
      //         <Upload
      //           listType="picture-card"
      //           fileList={[
      //             {
      //               uid: '-1',
      //               name: 'image.png',
      //               status: 'done',
      //               url: record.image_base64,
      //             },
      //           ]}
      //           maxCount={1}
      //           iconRender={() => {
      //             return <Spin></Spin>;
      //           }}
      //           beforeUpload={(file) => {
      //             return false;
      //           }}
      //         >
      //           {imageEdit ? imageEdit : <Button icon={<UploadOutlined />}></Button>}
      //         </Upload>
      //       </Form.Item>
      //     );
      //   } else {
      //     return imageEdit ? imageEdit : imageDefault;
      //   }
      // }

      if (dataIndex === "name") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.name}
            </span>
          );
        }
      }

      if (dataIndex === "price") {
        if (record.active) {
          return (
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input type="number" style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.price === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.price}
            </span>
          );
        }
      }

      if (dataIndex === "length_limit") {
        if (record.active) {
          return (
            <Form.Item
              name="length_limit"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <InputNumber min={1} max={20} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.length_limit === "データを入力して下さい。"
                    ? "#d2d2d2"
                    : "",
              }}
            >
              {record.length_limit}
            </span>
          );
        }
      }

      if (dataIndex === "actions_text") {
        if (record.active && textStatus === "add") {
          return (
            <Space>
              <Button
                danger
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelText(textStatus === "edit" ? "edit" : record.id)
                  );
                  formText.resetFields();
                }}
              />
              <Button
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                danger
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelText(textStatus === "edit" ? "edit" : record.id)
                  );
                  formText.resetFields();
                }}
              />
              <Button
                type="default"
                icon={<EditOutlined />}
                title="Edit"
                onClick={() => {
                  dispatch(changeTextStatus("edit"));
                  dispatch(setRowTextEdit(record.id));
                  formText.setFieldsValue({
                    key: index,
                    name: record.name,
                    price: record.price,
                    length_limit: record.length_limit,
                  });
                }}
              />
              <Button
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
              <Popconfirm
                title="削除しますか？"
                okText="はい"
                cancelText="いいえ"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  handleDeleteRowText(record.id);
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title="Delete"
                ></Button>
              </Popconfirm>
            </Space>
          );
        }
      }

      // FONT
      // if (dataIndex === 'image_font') {
      //   const imageEdit = (
      //     <img src={record.image || record.image_base64} width={40} style={{ margin: '0 auto' }} alt="Error" />
      //   );
      //   if (record.active) {
      //     return (
      //       <Form.Item name="image_base64" valuePropName="fileList" getValueFromEvent={valueFile}>
      //         <Upload
      //           listType="picture-card"
      //           fileList={[
      //             {
      //               uid: '-1',
      //               name: 'image.png',
      //               status: 'done',
      //               url: record.image,
      //             },
      //           ]}
      //           maxCount={1}
      //           iconRender={() => {
      //             return <Spin></Spin>;
      //           }}
      //           beforeUpload={(file) => {
      //             return false;
      //           }}
      //         >
      //           {imageEdit ? imageEdit : <Button icon={<UploadOutlined />}></Button>}
      //         </Upload>
      //       </Form.Item>
      //     );
      //   } else {
      //     return imageEdit ? imageEdit : imageDefault;
      //   }
      // }

      if (dataIndex === "name_font") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.name}
            </span>
          );
        }
      }

      if (dataIndex === "actions_font") {
        if (record.active && fontStatus === "add") {
          return (
            <Space>
              <Button
                danger
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelFont(fontStatus === "edit" ? "edit" : record.id)
                  );
                  formFont.resetFields();
                }}
              />
              <Button
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                danger
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelFont(fontStatus === "edit" ? "edit" : record.id)
                  );
                  formFont.resetFields();
                }}
              />
              <Button
                type="default"
                icon={<EditOutlined />}
                title="Edit"
                onClick={() => {
                  dispatch(changeFontStatus("edit"));
                  dispatch(setRowFontEdit(record.id));
                  formFont.setFieldsValue({
                    key: index,
                    name: record.name,
                    image: record.image,
                  });
                }}
              />
              <Button
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
              <Popconfirm
                title="削除しますか？"
                okText="はい"
                cancelText="いいえ"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  handleDeleteRowFont(record.id);
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title="Delete"
                ></Button>
              </Popconfirm>
            </Space>
          );
        }
      }

      // COLOR
      if (dataIndex === "color_code") {
        if (record.active) {
          return (
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <span>{colorChange ? colorChange : "Please Select Color!"}</span>
              <Form.Item
                name="color_code"
                rules={[
                  {
                    required: true,
                    message: "データを入力して下さい。",
                  },
                ]}
              >
                <Input
                  type="color"
                  value={colorChange}
                  style={{ width: "80px", height: "35px" }}
                  onBlur={(e) => {
                    setColorChange(e.target.value);
                  }}
                />
              </Form.Item>
            </Space>
          );
        } else {
          return (
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <span>{record.color_code}</span>
              <div
                className="color-box"
                style={{ backgroundColor: record.color_code }}
              ></div>
            </Space>
          );
        }
      }

      if (dataIndex === "color_name") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.name}
            </span>
          );
        }
      }

      if (dataIndex === "actions_color") {
        if (record.active && colorStatus === "add") {
          return (
            <Space>
              <Button
                danger
                type="primary"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelColor(colorStatus === "edit" ? "edit" : record.id)
                  );
                  formColor.resetFields();
                }}
              />
              <Button
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                danger
                disabled={!record.active}
                type="primary"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelColor(colorStatus === "edit" ? "edit" : record.id)
                  );
                  formColor.resetFields();
                }}
              />
              <Button
                type="default"
                icon={<EditOutlined />}
                title="Edit"
                onClick={() => {
                  dispatch(changeColorStatus("edit"));
                  dispatch(setRowColorEdit(record.id));
                  formColor.setFieldsValue({
                    key: index,
                    name: record.name,
                    color_code: record.color_code,
                  });
                }}
              />
              <Button
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
              <Popconfirm
                title="削除しますか？"
                okText="はい"
                cancelText="いいえ"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  deleteRowColors(record.id);
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title="Delete"
                ></Button>
              </Popconfirm>
            </Space>
          );
        }
      }
    },
  });

  // Get Image
  const getImageStyleText = (data: any, face: number) => {
    const temp = data?.filter((item: any) => item.face === face);
    return temp ? temp[0] : [];
  };

  // Get List Face
  const getListFace = async () => {
    try {
      const res: any = await facesApi.getListFace();
      if (res.status === "success") {
        setFaces(res.data);
        setActiveFace(res.data[0].face);
      }
    } catch (err) {}
  };

  const columnsText: ColumnsType<DataTypeStyle> = [
    {
      title: "順",
      dataIndex: "no",
      width: "10%",
      align: "center",
      render: (data: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: "20%",
      className: "hidden-row",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    {
      title: "テキスト範囲",
      dataIndex: "name",
      // width: '50%',
      align: "center",
      ...getColumnSearchProps("name"),
    },
    {
      title: "価格",
      width: "20%",
      dataIndex: "price",
      align: "center",
      ...getColumnSearchProps("price"),
    },
    {
      title: "最大文字数",
      width: "20%",
      dataIndex: "length_limit",
      align: "center",
      ...getColumnSearchProps("length_limit"),
    },
    {
      title: "操作",
      dataIndex: "actions",
      align: "center",
      ...getColumnSearchProps("actions_text"),
    },
  ];

  const columnsFont: ColumnsType<any> = [
    {
      title: "順",
      dataIndex: "no",
      align: "center",
      render: (data: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: "20%",
      className: "hidden-row",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    // {
    //   title: 'Font Image',
    //   dataIndex: 'image_font',
    //   width: '10%',
    //   align: 'center',
    //   ...getColumnSearchProps('image_font'),
    // },
    {
      title: "書体名",
      dataIndex: "name_font",
      align: "center",
      ...getColumnSearchProps("name_font"),
    },
    {
      title: "操作",
      dataIndex: "actions_font",
      align: "center",
      ...getColumnSearchProps("actions_font"),
    },
  ];

  const columnsColor: ColumnsType<DataTypeColor> = [
    {
      title: "順",
      dataIndex: "no",
      align: "center",
      key: "no",
      render: (data, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Key",
      dataIndex: "key",
      className: "hidden-row",
      key: "key-table",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    {
      title: "カラーコード",
      dataIndex: "color_code",
      width: "25%",
      align: "center",
      key: "color_code",
      ...getColumnSearchProps("color_code"),
    },
    {
      title: "名前",
      dataIndex: "color_name",
      width: "30%",
      align: "center",
      key: "color_name",
      ...getColumnSearchProps("color_name"),
    },
    {
      title: "操作",
      dataIndex: "actions_color",
      align: "center",
      key: "actions_color",
      ...getColumnSearchProps("actions_color"),
    },
  ];

  // Change Generate
  const HandleChangeGenerate = async (e: any, data: any) => {
    // Handle Convert Iamge Base64
    const handleConvertImage = await UseConvertImage(
      e.fileList[0]?.originFileObj
    );

    // Check exist data change
    const checkValue = (data: any) =>
      itemGenerateChange.filter(
        (item: any) =>
          item.style_color_id === data.style_color_id && item.face === data.face
      );

    setTimeout(() => {
      // Check exist image
      if (e.fileList[0]) {
        //Check Data Change Duplicate
        if (checkValue(data).length > 0) {
          const handleDuplicate = itemGenerateChange.map((item: any) =>
            item.style_color_id === checkValue(data)[0].style_color_id &&
            item.face === checkValue(data)[0].face
              ? { ...checkValue(data)[0], image_base64: handleConvertImage }
              : item
          );
          // Update List Data
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face
                        ? { ...i, image: handleConvertImage }
                        : i
                    ),
                  ],
                }
              : item
          );

          setItemGenerateChange([...handleDuplicate]);
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        } else {
          setItemGenerateChange([
            ...itemGenerateChange,
            { ...data, image_base64: handleConvertImage },
          ]);
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face
                        ? { ...i, image: handleConvertImage }
                        : i
                    ),
                  ],
                }
              : item
          );
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        }
      } else {
        if (checkValue(data).length > 0) {
          const handleDataDelete = itemGenerateChange.map((item: any) =>
            item.style_color_id === data.style_color_id &&
            item.face === data.face
              ? { ...data, image_base64: "" }
              : item
          );
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face ? { ...i, image: "" } : i
                    ),
                  ],
                }
              : item
          );
          setItemGenerateChange([...handleDataDelete]);
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        } else {
          setItemGenerateChange([
            ...itemGenerateChange,
            { ...data, image_base64: "" },
          ]);
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face ? { ...i, image: "" } : i
                    ),
                  ],
                }
              : item
          );
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        }
      }
    }, 300);
  };

  // SAVE
  const handleSaveAll = async () => {
    setStatusLoader(true);
    setItemGenerateChange([]);
    try {
      if (itemGenerateChange.length) {
        const resImage: any = await styleColorApi.updateImageStyleColor(
          itemGenerateChange
        );
        if (resImage.status === "success") {
          setTimeout(() => {
            setStatusLoader(false);
            alertSuccess("保存しました。");
          }, 500);
        }
      }
      if (listUpdateStyleColor.length) {
        const resStatus: any = await styleColorApi.updateStatusStyleColor(
          listUpdateStyleColor
        );
        if (resStatus.status === "success") {
          setTimeout(() => {
            setStatusLoader(false);
            alertSuccess("保存しました。");
          }, 500);
        }
      }
    } catch (err) {
      alertFail("保存に失敗しました。");
    }
  };

  useEffect(() => {
    dispatch(getText(style_id));
    dispatch(getStyleById(style_id));
    // eslint-disable-next-line
  }, [item_id, style_id, dispatch]);

  useEffect(() => {
    getListFace();
    setItemGenerateChange([]);
  }, []);

  return (
    <Layout
      className="style-color min-height-layout"
      style={{ padding: "24px" }}
    >
      {showPopup}
      {statusLoader ? overLoader() : ""}
      <p className="title-view">{`文章 ${dataStyleById?.name}`}</p>
      <Breadcrumb
        style={{ margin: "16px 0" }}
        items={[
          { title: itemById.name },
          { title: `文章 ${dataStyleById?.name}` },
        ]}
      ></Breadcrumb>
      <Content className="site-layout-background content-wrap">
        {/* Table Texts */}
        <div className="table-wrap">
          <Form form={formText} onFinish={onFinishText}>
            <Table
              rowKey="key"
              loading={loading}
              columns={columnsText}
              dataSource={dataText}
              bordered
              className="text-center"
              title={() => (
                <p>
                  テキスト範囲
                  <Button
                    disabled={dataText.length ? true : false}
                    type="primary"
                    onClick={handleAddRowText}
                    icon={<PlusCircleOutlined />}
                  >
                    テキスト範囲を使用
                  </Button>
                </p>
              )}
            ></Table>
          </Form>
        </div>

        {/* Table Fonts */}
        <div className="table-wrap">
          <Form form={formFont} onFinish={onFinishFont}>
            <Table
              rowKey="key"
              loading={loading}
              columns={columnsFont}
              dataSource={dataFont}
              bordered
              className="text-center"
              title={() => (
                <p>
                  書体
                  <Button
                    type="primary"
                    onClick={handleAddRowFont}
                    icon={<PlusCircleOutlined />}
                  >
                    書体追加
                  </Button>
                </p>
              )}
            ></Table>
          </Form>
        </div>

        {/* Table Colors */}
        <div className="table-wrap">
          <Form onFinish={onFinishColor} form={formColor}>
            <Table
              rowKey="key"
              loading={loading}
              columns={columnsColor}
              dataSource={dataColor}
              bordered
              className="text-center"
              title={() => (
                <p>
                  カラー
                  <Button
                    onClick={addRowColors}
                    type="primary"
                    icon={<PlusCircleOutlined />}
                  >
                    カラーを追加
                  </Button>
                </p>
              )}
            ></Table>
          </Form>
        </div>
      </Content>

      {/* List Render */}
      <Content className="list-render">
        <div className="select-face">
          {faces?.map((item: Face) => (
            <span
              key={item.id}
              className={`face ${item.face === activeFace ? "active" : ""}`}
              onClick={() => setActiveFace(item.face)}
            >
              {item.name}
            </span>
          ))}
        </div>

        {dataFont?.map((font: any) => {
          return (
            <div key={font.id} className="wrap">
              <div className="title-style-colors">
                <Tag
                  icon={<FontSizeOutlined />}
                  color="#2fa5ff"
                  style={{ fontSize: 15 }}
                >
                  {`${font.name}`}
                </Tag>
              </div>
              <div className="list-item-render">
                {dataStyleColor?.map((item: any, index: number) =>
                  item.font_id === font.id ? (
                    <Fragment key={index}>
                      {font.id === item.font_id ? (
                        <div className="item-render">
                          {/* <Checkbox
                            key={`checkbox-item-${index}`}
                            name={`checkbox-item-${index}`}
                            checked={item?.status === 2 ? false : true}
                            onChange={(e) => {
                              dispatch(handleCheckboxStatus({ e, item }));
                            }}
                            className="select-item-render"
                          ></Checkbox> */}
                          <>
                            {getImageStyleText(item.infos, activeFace)
                              ?.image ? (
                              <Upload
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(e) =>
                                  HandleChangeGenerate(e, {
                                    style_color_id: item.id,
                                    face: activeFace,
                                  })
                                }
                                fileList={[
                                  {
                                    uid: "-1",
                                    name: "image.png",
                                    url: getImageStyleText(
                                      item.infos,
                                      activeFace
                                    )?.image,
                                  },
                                ]}
                              />
                            ) : (
                              <Upload
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(e) =>
                                  HandleChangeGenerate(e, {
                                    style_color_id: item.id,
                                    face: activeFace,
                                  })
                                }
                              >
                                {uploadButton}
                              </Upload>
                            )}
                          </>
                          <div className="name-item-render">{`${item.name}`}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          );
        })}
      </Content>

      {
        <div className="btn-save">
          <Button
            type="primary"
            onClick={() => {
              handleSaveAll();
            }}
            icon={<SaveOutlined />}
            size="large"
            style={{ marginTop: 30 }}
            disabled={
              !(itemGenerateChange.length > 0) &&
              listUpdateStyleColor.length === 0
            }
          >
            保存
          </Button>
        </div>
      }
    </Layout>
  );
};
