import { useEffect, useState } from "react";
import {
  EditOutlined,
  PlusCircleOutlined,
  FilterOutlined,
  RollbackOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import type { ColumnsType } from "antd/es/table";
import { useSearchParams, Link } from "react-router-dom";
import {
  Image,
  Button,
  Table,
  Breadcrumb,
  Layout,
  Space,
  Popconfirm,
  Input,
  notification,
  Pagination,
  Tag,
  Modal,
} from "antd";
import {
  deleteProduct,
  getProductsById,
  getProductsWithSearch,
} from "../../../redux/services/productSlice";
import { useSelector } from "react-redux";
import { getLocalStorage } from "../../../utils/localStorage";
import patchDynamic from "../../../helper/pathDynamic";
import { EditProduct } from "../editProduct";
import { ProductDulicateModal } from "../../../modules/admin/productDulicateModal";
import imageDefault from "../../../helper/imageDefault";

interface DataType {
  id: number;
  name: string;
  price: any;
  image: string;
  description: string;
}

export const ListProduct = () => {
  const role_user: any = getLocalStorage("role_user");

  const { Content } = Layout;
  const { Search } = Input;
  const [api, showPopup] = notification.useNotification();
  const { dataProductById, productsWithSearch, loading } = useSelector(
    (state: any) => state.productReducer
  );
  const [searchValue, setSearchValue] = useState("");
  const [clickSearch, setClickSearch] = useState(true);
  const [searchStatus, setSearchStatus] = useState(false);
  const [pageSize, setPageSize] = useState("1");
  const [open, setOpen] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(true);
  const dispatch = useDispatch();

  let [searchParams, setSearchParams] = useSearchParams({
    page_index: "1",
    page_size: "10",
  });

  useEffect(() => {
    if (searchValue !== "") {
      setSearchParams({ page_index: pageSize, q: searchValue });
    }
    if (searchValue === "" && searchStatus) {
      setSearchParams({ page_index: pageSize });
    }
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    if (searchParams.get("page_index") === null) {
      setSearchParams({ page_index: "1" });
    }
    // eslint-disable-next-line
  }, [searchParams]);

  // Config Table
  const columns: ColumnsType<DataType> = [
    {
      title: "名前",
      dataIndex: "name",
    },
    {
      title: "価格",
      dataIndex: "price",
    },
    {
      title: "画像",
      dataIndex: "image",

      render: (url: string) =>
        url ? (
          <Image width={35} src={url} />
        ) : (
          <Image width={35} src={imageDefault} />
        ),
    },
    {
      title: "説明",
      dataIndex: "description",
      width: "20%",
      render: (text: string) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "配送料",
      dataIndex: "shipping_costs",
    },
    {
      title: "状態",
      key: "status",
      dataIndex: "status",
      render: (status: string) => (
        <span>
          <Tag
            color={status === "非公開" ? "volcano" : "green"}
            key={status}
            style={{ margin: 0 }}
          >
            {status.toUpperCase()}
          </Tag>
        </span>
      ),
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (_, dataForm: DataType) => (
        <Space>
          <Button
            type="primary"
            ghost
            onClick={() => {
              handleDuplicate(dataForm.id);
            }}
          >
            <CopyOutlined />
            複製
          </Button>

          <Link
            onClick={() => {
              handleEdit(dataForm.id);
            }}
            to={`/admin-${patchDynamic}/products/${dataForm.id}/setting`}
          >
            <Button type="default">
              <EditOutlined />
              編集
            </Button>
          </Link>
          <Popconfirm
            title="この商品を削除しますか?"
            okText="はい"
            cancelText="いいえ"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteProduct(dataForm.id)}
          >
            <Button danger>
              <CloseCircleOutlined />
              削除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Alert
  const alertLoginSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };
  const alertLoginFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  /* Handle Product Duplicate */
  const handleDuplicate = (product_id: number) => {
    dispatch(getProductsById(product_id));
    setOpen(true);
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);
    }, 2000);
  };

  // Handle Edit Product
  const handleEdit = (id: any) => {
    localStorage.setItem("productId", id);
  };

  // Handle Delete Product
  const handleDeleteProduct = async (id: any) => {
    try {
      // if (clickSearch) {
      const res = await dispatch(deleteProduct(id));
      if (res.payload.response.message === "Delete successfully") {
        alertLoginSuccess("削除済み");
      }
      // }
    } catch (err) {
      alertLoginFail("削除失敗");
    }
  };

  const handlePagination = async (page: number, pageSize: number) => {
    if (searchValue === "") {
      setSearchParams({ page_index: page.toString() }, { replace: true });
    } else {
      setSearchParams(
        { page_index: page.toString(), q: searchValue },
        { replace: true }
      );
    }
    setPageSize(page.toString());
    setClickSearch(true);
  };

  async function getListProducts(searchValue?: string) {
    let searchPath = "";

    if (searchStatus) {
      searchPath = `p=${searchParams.get(
        "page_index"
      )}&q=${searchValue}&page=${searchParams.get("page_index")}`;
    } else {
      searchPath = `page=${searchParams.get("page_index")}`;
    }
    const res = await dispatch(getProductsWithSearch(searchPath));
    if (res.payload.status === "success") {
      setClickSearch(false);
    }
  }

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = (value: string | undefined) => {
    setSearchStatus(true);
    setClickSearch(true);
  };

  useEffect(() => {
    if (searchStatus) {
      if (clickSearch) {
        getListProducts(searchValue);
      }
    } else {
      if (clickSearch) {
        getListProducts();
      }
    }
    // eslint-disable-next-line
  }, [searchParams, searchStatus, clickSearch]);

  return (
    <Layout className="min-height-layout" style={{ padding: "24px" }}>
      {showPopup}
      <p className="title-view">商品一覧</p>
      <Breadcrumb
        style={{ margin: "16px 0" }}
        items={[{ title: "商品" }, { title: "商品一覧" }]}
      ></Breadcrumb>
      <Content
        className="site-layout-background"
        style={{ padding: "24px 0", margin: 0 }}
      >
        <div className="list-btn">
          {Number(role_user) === 0 && productsWithSearch?.data?.length > 1 ? (
            <Button type="primary" disabled={true}>
              <PlusCircleOutlined />
              商品を追加
            </Button>
          ) : (
            <Link to={`/admin-${patchDynamic}/products/add-product`}>
              <Button type="primary">
                <PlusCircleOutlined />
                商品を追加
              </Button>
            </Link>
          )}

          <div className="right">
            <Search
              placeholder="商品検索"
              style={{ width: 200 }}
              onSearch={handleSearch}
              onChange={handleSearchValue}
              value={searchValue}
            />
            <Button type="primary" className="btn-filter" title="Filter">
              <FilterOutlined />
              絞り込み
            </Button>
            <Button
              title="Reset"
              onClick={() => {
                setSearchStatus(false);
                setSearchValue("");
                getListProducts();
                setSearchParams({ page_index: "1" });
                setClickSearch(true);
              }}
            >
              <RollbackOutlined />
              リセット
            </Button>
          </div>
        </div>

        {/* Tables */}
        <div className="manager-products">
          <Table
            loading={loading}
            // rowSelection={{ type: 'checkbox' }}
            rowKey="key"
            columns={columns}
            dataSource={productsWithSearch?.data}
            pagination={false}
          />
        </div>
        <Space
          style={{ width: "100%", marginTop: "2rem" }}
          direction="vertical"
          align="end"
        >
          <Pagination
            className="fix-pagination"
            onChange={handlePagination}
            current={Number(searchParams.get("page_index"))}
            pageSize={10}
            total={productsWithSearch?.total}
            pageSizeOptions={[5, 10, 15, 20, 25, 30]}
            showSizeChanger
          />
        </Space>

        {/* Modal Duplicate */}
        <Modal
          className="modal-duplicate-product"
          title={<p>Loading Modal</p>}
          footer={false}
          loading={loadingModal}
          open={open}
          onCancel={() => setOpen(false)}
        >
          <Space style={{ marginTop: "30px", width: "100%" }}>
            <ProductDulicateModal
              dataProductById={dataProductById}
              labelCol={4}
            />
          </Space>
        </Modal>
      </Content>
    </Layout>
  );
};
