import axiosClient from '../axiosClient';

export const styleColorApi = {
  async addStyle(data: any) {
    const url = `/styles`;
    return axiosClient.post(url, data);
  },

  async addColor(data: any) {
    const url = `/colors`;
    return axiosClient.post(url, data);
  },

  async getItemById(id: any) {
    const url = `/items/${id}`;
    return axiosClient.get(url);
  },

  async getStyleById(id: any) {
    const url = `/styles/${id}`;
    return axiosClient.get(url);
  },

  // async getStyleColorImageByItemFace(idItem: any, face: any) {
  //   const url = `/customer/get-style-color-image-by-item-and-face/${idItem}/${face}`;
  //   return axiosClient.get(url);
  // },

  async editStyle(data: any) {
    const url = `/styles/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  async editColor(data: any) {
    const url = `/colors/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  async deleteRowStyle(id: any) {
    const url = `/styles/${id}`;
    return axiosClient.delete(url);
  },

  async deleteRowColor(id: any) {
    const url = `/colors/${id}`;
    return axiosClient.delete(url);
  },

  // async updateImageStyleColor(data: any) {
  //   const url = `/style-colors`;
  //   return axiosClient.post(url, data);
  // },

  async updateImageStyleColor(data: any) {
    const url = `/style-color-images`;
    return axiosClient.post(url, data);
  },

  async updateStatusStyleColor(data: any) {
    const url = `style-colors/update-status`;
    return axiosClient.post(url, data);
  },

  async getStyleColorImageByStyleColorId(id: any) {
    const url = `customer/get-style-color-image-by-style-color/${id}`;
    return axiosClient.get(url);
  },

  async getStyleColorImageByItemFace(idItem: any, face: any) {
    const url = `/customer/get-style-color-image-by-item-and-face/${idItem}/${face}`;
    return axiosClient.get(url);
  },

  async updateSubStyleIndex(data: any) {
    const url = '/styles/update-style-index';
    return axiosClient.post(url, data);
  },
};
