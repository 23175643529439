import axiosClient from '../axiosClient';

export const facesApi = {
  async getListFace() {
    const url = '/customer/faces';
    return axiosClient.get(url);
  },

  async getListFaceExistImage(id: number) {
    const url = `/customer/faces/${id}`;
    return axiosClient.get(url);
  },
};
