import { Fragment, useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  EditOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  // UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  ApartmentOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Layout,
  Input,
  Form,
  Upload,
  notification,
  Table,
  Space,
  Popconfirm,
  Checkbox,
  InputNumber,
  // Spin,
} from "antd";
import {
  DataTypeColor,
  DataGenerateChange,
  DataTypeStyle,
} from "../../../types/styleColor";
import imageDefault from "../../../helper/imageDefault";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  addColor,
  addRowColor,
  addRowStyle,
  addStyle,
  cancelColor,
  cancelStyle,
  changeColorStatus,
  changeStyleStatus,
  deleteRowColor,
  deleteRowStyle,
  editColor,
  editStyle,
  getItemById,
  // handleCheckboxStatus,
  setDataStyleColor,
  setRowColorEdit,
  setRowEdit,
} from "../../../redux/services/styleColorSlice";
import { styleColorApi } from "../../../api/styleColorApi/styleColorApi";
import { getProductsById } from "../../../redux/services/productSlice";
import { Face } from "../../../types/faces";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { facesApi } from "../../../api";
import patchDynamic from "../../../helper/pathDynamic";
import { getLocalStorage } from "../../../utils/localStorage";
import overLoader from "../../../components/overLoader";
import ColorInput from "../../../components/ColorInput";
import { UseConvertImage } from "../../../hooks/useConvertImage";

export const StyleColor = () => {
  const role_user: any = getLocalStorage("role_user");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { product_id, item_id } = useParams();
  const [api, showPopup] = notification.useNotification();
  const [itemGenerateChange, setItemGenerateChange] = useState<
    DataGenerateChange[]
  >([]);
  const [colorNow, setColorNow] = useState("");
  const [colorChange, setColorChange] = useState("");
  const [formStyle] = Form.useForm();
  const [formColor] = Form.useForm();
  const [faces, setFaces] = useState<any>([]);
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [activeFace, setActiveFace] = useState<number>(faces[0]?.face);
  const [checkRecommend, setCheckRecommend] = useState<number>(-1);

  const { productName } = useSelector((state: any) => state.productReducer);

  const {
    itemById,
    dataStyle,
    dataColor,
    dataStyleColor,
    styleStatus,
    loading,
    idRowEdit,
    colorStatus,
    idRowColorEdit,
    listUpdateStyleColor,
  } = useSelector((state: any) => state.styleColorReducer);

  const [handleDataStyle, setHandleDataStyle] = useState<any>([]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
    </div>
  );

  useEffect(() => {
    setHandleDataStyle([...dataStyle]);
  }, [dataStyle]);

  useEffect(() => {
    dispatch(getItemById(item_id));
    dispatch(getProductsById(product_id));
    // eslint-disable-next-line
  }, [item_id, dispatch]);

  const valueFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  // STYLE

  const onFinishStyle = async (values: any) => {
    const checkExistImage = values.image_base64?.[0];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleBase64 = checkExistImage
      ? await UseConvertImage(values.image_base64?.[0]?.originFileObj)
      : null;
    const newStyles = handleBase64
      ? {
          name: values.name,
          item_id: item_id,
          image_base64: handleBase64,
          price: role_user === 0 ? 0 : Number(values.price),
          status: 1,
        }
      : {
          name: values.name,
          item_id: item_id,
          // image_base64: '',
          price: role_user === 0 ? 0 : Number(values.price),
          status: 1,
        };

    try {
      if (styleStatus === "add") {
        const res = await dispatch(
          addStyle(
            checkRecommend !== -1
              ? { ...newStyles, suggestion: checkRecommend }
              : { ...newStyles, suggestion: 0 }
          )
        );
        if (res.payload.status === "success") {
          dispatch(cancelStyle("add"));
          const newData = {
            name: res?.payload?.data?.name,
            image_base64: res?.payload?.data?.image,
            price: res?.payload?.data?.price,
            key: res?.payload?.data?.style_id,
            suggestion: res?.payload?.data?.suggestion,
          };
          dispatch(addRowStyle(newData));
          dispatch(getItemById(item_id));
          setCheckRecommend(-1);
          alertSuccess("保存しました。");
          formStyle.resetFields();
        }
      } else {
        const res = await dispatch(
          editStyle({
            id: idRowEdit,
            body:
              checkRecommend !== -1
                ? { ...newStyles, suggestion: checkRecommend }
                : { ...newStyles },
          })
        );
        setCheckRecommend(-1);
        if (res.payload.status === "success") {
          alertSuccess("保存しました。");
          formStyle.resetFields();
          dispatch(getItemById(item_id));
        }
      }
    } catch (err) {
      alertFail("無効なデータ。 再入力してください。");
    }
  };

  const addRowStyles = async () => {
    const rowsInput: any = {
      key: (dataStyle.length + 1).toString(),
      image_base64: imageDefault,
      name: "",
      price: 0,
      active: true,
      suggestion: 0,
    };
    dispatch(addRowStyle(rowsInput));
    dispatch(changeStyleStatus("add"));
  };

  const changeImageStyle = async (e: any, idStyle: number) => {
    const checkExistImage = await e.fileList[0];
    setTimeout(() => {
      if (checkExistImage) {
        const tempData = handleDataStyle?.map((style: any) =>
          style.id === idStyle
            ? { ...style, image: checkExistImage?.thumbUrl }
            : style
        );
        setHandleDataStyle([...tempData]);
      } else {
        const tempData = handleDataStyle?.map((style: any) =>
          style.id === idStyle ? { ...style, image: "" } : style
        );
        setHandleDataStyle([...tempData]);
      }
    }, 300);
  };

  const deleteRowStyles = async (idDelete: any) => {
    try {
      const res = await dispatch(deleteRowStyle(idDelete));
      if (res.payload !== undefined) {
        alertSuccess("削除しました。");
        formStyle.resetFields();
      }
    } catch (err) {
      alertFail("削除に失敗しました。");
    }
  };

  // COLOR

  const addRowColors = async () => {
    const rowsInput: any = {
      id: product_id?.toString(),
      key: product_id?.toString(),
      name: "データを入力して下さい。",
      color_code: "#ffffff",
      active: true,
    };
    dispatch(addRowColor(rowsInput));
    dispatch(changeColorStatus("add"));
  };

  const onFinishColor = async (values: any) => {
    const dataNew = {
      name: values.name,
      color_code: colorChange,
      item_id: item_id,
    };

    try {
      if (colorStatus === "add") {
        const res = await dispatch(addColor(dataNew));
        if (res.payload.status === "success") {
          dispatch(cancelColor("add"));
          dispatch(getItemById(item_id));
          dispatch(addRowColor(dataNew));
          alertSuccess("保存しました。");
          formColor.resetFields();
        } else if (res.payload.status === "fail") {
          alertFail(res.payload.data.message);
        }
      } else {
        const res = await dispatch(
          editColor({ id: idRowColorEdit, body: dataNew })
        );
        if (res.payload.status === "success") {
          alertSuccess("保存しました。");
          dispatch(getItemById(item_id));
          formColor.resetFields();
        }
      }
    } catch (err) {
      alertFail("追加に失敗しました。");
    }
  };

  // Delete Row Colors
  const deleteRowColors = async (idDelete: any) => {
    try {
      const res = await dispatch(deleteRowColor(idDelete));
      if (res.payload !== undefined) {
        alertSuccess("削除しました。");
        dispatch(getItemById(item_id));
      }
    } catch (err) {
      alertFail("削除に失敗しました。");
    }
  };

  // Change Generate
  const HandleChangeGenerate = async (e: any, data: any) => {
    // Handle Convert Iamge Base64
    const handleConvertImage = await UseConvertImage(
      e.fileList[0]?.originFileObj
    );

    // Check exist data change
    const checkValue = (data: any) =>
      itemGenerateChange.filter(
        (item: any) =>
          item.style_color_id === data.style_color_id && item.face === data.face
      );

    setTimeout(() => {
      // Check exist image
      if (e.fileList[0]) {
        //Check Data Change Duplicate
        if (checkValue(data).length > 0) {
          const handleDuplicate = itemGenerateChange.map((item: any) =>
            item.style_color_id === checkValue(data)[0].style_color_id &&
            item.face === checkValue(data)[0].face
              ? { ...checkValue(data)[0], image_base64: handleConvertImage }
              : item
          );
          // Update List Data
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face
                        ? { ...i, image: handleConvertImage }
                        : i
                    ),
                  ],
                }
              : item
          );

          setItemGenerateChange([...handleDuplicate]);
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        } else {
          setItemGenerateChange([
            ...itemGenerateChange,
            { ...data, image_base64: handleConvertImage },
          ]);
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face
                        ? { ...i, image: handleConvertImage }
                        : i
                    ),
                  ],
                }
              : item
          );
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        }
      } else {
        if (checkValue(data).length > 0) {
          const handleDataDelete = itemGenerateChange.map((item: any) =>
            item.style_color_id === data.style_color_id &&
            item.face === data.face
              ? { ...data, image_base64: "" }
              : item
          );
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face ? { ...i, image: "" } : i
                    ),
                  ],
                }
              : item
          );
          setItemGenerateChange([...handleDataDelete]);
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        } else {
          setItemGenerateChange([
            ...itemGenerateChange,
            { ...data, image_base64: "" },
          ]);
          const handleUpdateDataLoop = dataStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face ? { ...i, image: "" } : i
                    ),
                  ],
                }
              : item
          );
          dispatch(setDataStyleColor([...handleUpdateDataLoop]));
        }
      }
    }, 300);
  };

  // Change Status Recommend
  const handleChangeStatusRecommend = (e: CheckboxChangeEvent) => {
    const convertStatus = e.target.checked ? 1 : 0;
    setCheckRecommend(convertStatus);
  };

  // SAVE
  const handleSaveAll = async () => {
    setStatusLoader(true);
    setItemGenerateChange([]);
    try {
      if (itemGenerateChange.length) {
        const resImage: any = await styleColorApi.updateImageStyleColor(
          itemGenerateChange
        );
        if (resImage.status === "success") {
          setTimeout(() => {
            setStatusLoader(false);
            alertSuccess("保存しました。");
          }, 500);
        }
      }
      if (listUpdateStyleColor.length) {
        const resStatus: any = await styleColorApi.updateStatusStyleColor(
          listUpdateStyleColor
        );
        if (resStatus.status === "success") {
          setTimeout(() => {
            setStatusLoader(false);
            alertSuccess("保存しました。");
          }, 500);
        }
      }
    } catch (err) {
      alertFail("保存に失敗しました。");
    }
  };

  const getColorFromInput = (color: any) => {
    setColorChange(color);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    render: (text: any, record: any, index: any) => {
      // STYLE

      if (dataIndex === "image") {
        const imageEdit = (
          <img
            src={record.image || record.image_base64 || imageDefault}
            width={40}
            style={{ margin: "0 auto" }}
            alt="Error"
          />
        );
        if (record.active) {
          return (
            <Form.Item
              name="image_base64"
              valuePropName=""
              getValueFromEvent={valueFile}
              style={{ width: 80 }}
            >
              {record.image ? (
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => {
                    return false;
                  }}
                  fileList={[
                    {
                      uid: "-1",
                      name: "image.png",
                      status: "done",
                      url: record.image,
                    },
                  ]}
                  onChange={(e) => changeImageStyle(e, record.id)}
                />
              ) : (
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(e) => changeImageStyle(e, record.id)}
                >
                  {uploadButton}
                </Upload>
              )}
            </Form.Item>
          );
        } else {
          return imageEdit ? imageEdit : imageDefault;
        }
      }

      if (dataIndex === "name") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.name}
            </span>
          );
        }
      }

      if (dataIndex === "price") {
        if (record.active) {
          return role_user === 0 ? (
            <Form.Item name="price">
              <Input disabled type="number" style={{ textAlign: "center" }} />
            </Form.Item>
          ) : (
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              {/* <Input type="number" style={{ textAlign: 'center' }} /> */}
              <InputNumber type="number" min={0} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.price === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.price}
            </span>
          );
        }
      }

      if (dataIndex === "recommend") {
        if (record.active) {
          return (
            <Checkbox
              key={`recommend-style-${index}`}
              onChange={handleChangeStatusRecommend}
            />
          );
        } else {
          return (
            <Checkbox
              key={`recommend-style-${index}`}
              checked={!!record.suggestion}
              disabled
            />
          );
        }
      }

      if (dataIndex === "actions") {
        if (record.active && styleStatus === "add") {
          return (
            <Space>
              <Button
                danger
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelStyle(styleStatus === "edit" ? "edit" : record.id)
                  );
                  formStyle.resetFields();
                }}
              />
              <Button
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                type="primary"
                ghost
                icon={<FontSizeOutlined />}
                title="Text Style"
                onClick={() => {
                  navigate(
                    `/admin-${patchDynamic}/products/${product_id}/item/${item_id}/textstyle/${record.id}`
                  );
                }}
                disabled={role_user ? false : true}
              />
              <Button
                type="primary"
                ghost
                icon={<ApartmentOutlined />}
                title="List Sub Style"
                onClick={() => {
                  navigate(
                    `/admin-${patchDynamic}/products/${product_id}/item/${item_id}/substyle/${record.id}`
                  );
                }}
              />
              <Button
                danger
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelStyle(styleStatus === "edit" ? "edit" : record.id)
                  );
                  formStyle.resetFields();
                }}
              />
              <Button
                type="default"
                icon={<EditOutlined />}
                title="Edit"
                onClick={() => {
                  dispatch(changeStyleStatus("edit"));
                  dispatch(setRowEdit(record.id));
                  formStyle.setFieldsValue({
                    key: index,
                    name: record.name,
                    price: record.price,
                  });
                }}
              />
              <Button
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
              <Popconfirm
                title="削除しますか？"
                okText="はい"
                cancelText="いいえ"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  deleteRowStyles(record.id);
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title="Delete"
                ></Button>
              </Popconfirm>
            </Space>
          );
        }
      }

      // COLOR

      if (dataIndex === "color_code") {
        if (record.active) {
          return (
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <span>
                {colorChange ? colorChange : "データを入力して下さい。"}
              </span>
              <ColorInput
                colorNow={colorNow}
                getColorFromInput={getColorFromInput}
              />
            </Space>
          );
        } else {
          return (
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <span>{record.color_code}</span>
              <div
                className="color-box"
                style={{ backgroundColor: record.color_code }}
              ></div>
            </Space>
          );
        }
      }

      if (dataIndex === "color_name") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.name}
            </span>
          );
        }
      }

      if (dataIndex === "actions_color") {
        if (record.active && colorStatus === "add") {
          return (
            <Space>
              <Button
                danger
                type="primary"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelColor(colorStatus === "edit" ? "edit" : record.id)
                  );
                  formColor.resetFields();
                }}
              />
              <Button
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                danger
                disabled={!record.active}
                type="primary"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelColor(colorStatus === "edit" ? "edit" : record.id)
                  );
                  formColor.resetFields();
                  setColorNow("");
                }}
              />
              <Button
                type="default"
                icon={<EditOutlined />}
                title="Edit"
                onClick={() => {
                  dispatch(changeColorStatus("edit"));
                  dispatch(setRowColorEdit(record.id));
                  setColorNow(record.color_code);
                  formColor.setFieldsValue({
                    key: index,
                    name: record.name,
                    color_code: record.color_code,
                  });
                }}
              />
              <Button
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
              <Popconfirm
                title="削除しますか？"
                okText="はい"
                cancelText="いいえ"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  deleteRowColors(record.id);
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title="Delete"
                ></Button>
              </Popconfirm>
            </Space>
          );
        }
      }
    },
  });

  // Get Image From Style Color and Face Product
  const getImageStyleColor = (data: any, face: number) => {
    const temp = data?.filter((item: any) => item.face === face);
    return temp ? temp[0] : [];
  };

  // Get List Face
  const getListFace = async () => {
    try {
      // if (clickSearch) {
      const res: any = await facesApi.getListFace();
      if (res.status === "success") {
        setFaces(res.data);
        setActiveFace(res.data[0].face);
      }
      // }
    } catch (err) {}
  };

  const columnsStyle: ColumnsType<DataTypeStyle> = [
    {
      title: "順",
      dataIndex: "no",
      align: "center",
      render: (data: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: "20%",
      className: "hidden-row",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    {
      title: "画像",
      dataIndex: "image",
      width: "30%",
      align: "center",
      ...getColumnSearchProps("image"),
    },
    {
      title: "名前",
      dataIndex: "name",
      width: "25%",
      align: "center",
      ...getColumnSearchProps("name"),
    },
    {
      title: "価格",
      width: "20%",
      dataIndex: "price",
      align: "center",
      ...getColumnSearchProps("price"),
    },
    {
      title: "レコメンド",
      width: "15%",
      dataIndex: "suggestion",
      align: "center",
      ...getColumnSearchProps("recommend"),
    },
    {
      title: "操作",
      dataIndex: "actions",
      align: "center",
      ...getColumnSearchProps("actions"),
    },
  ];

  const columnsColor: ColumnsType<DataTypeColor> = [
    {
      title: "順",
      dataIndex: "no",
      align: "center",
      key: "no",
      render: (data, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: "36%",
      className: "hidden-row",
      key: "key-table",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    {
      title: "カラーコード",
      dataIndex: "color_code",
      align: "center",
      width: "36%",
      key: "color_code",
      ...getColumnSearchProps("color_code"),
    },
    {
      title: "名前",
      dataIndex: "color_name",
      width: "20%",
      align: "center",
      key: "color_name",
      ...getColumnSearchProps("color_name"),
    },
    {
      title: "操作",
      dataIndex: "actions_color",
      align: "center",
      key: "actions_color",
      ...getColumnSearchProps("actions_color"),
    },
  ];

  useEffect(() => {
    getListFace();
  }, []);

  return (
    <Layout
      className="style-color min-height-layout"
      style={{ padding: "24px" }}
    >
      {showPopup}
      {statusLoader ? overLoader() : ""}
      <p className="title-view">{itemById.name}</p>
      <Breadcrumb
        style={{ margin: "16px 0" }}
        items={[{ title: productName }, { title: itemById.name }]}
      ></Breadcrumb>
      <div className="site-layout-background content-wrap">
        {/* Table Styles */}
        <div className="table-wrap form-upload-style">
          <Form form={formStyle} onFinish={onFinishStyle}>
            <Table
              rowKey="id"
              loading={loading}
              columns={columnsStyle}
              // dataSource={dataStyle}
              dataSource={handleDataStyle}
              bordered
              className="text-center"
              title={() => (
                <p>
                  スタイル
                  <Button
                    type="primary"
                    onClick={addRowStyles}
                    icon={<PlusCircleOutlined />}
                  >
                    スタイルを追加
                  </Button>
                </p>
              )}
            ></Table>
          </Form>
        </div>

        {/* Table Colors */}
        <div className="table-wrap">
          <Form form={formColor} onFinish={onFinishColor}>
            <Table
              rowKey="id"
              loading={loading}
              columns={columnsColor}
              dataSource={dataColor}
              bordered
              className="text-center"
              title={() => (
                <p>
                  カラー
                  <Button
                    type="primary"
                    onClick={addRowColors}
                    icon={<PlusCircleOutlined />}
                  >
                    カラーを追加
                  </Button>
                </p>
              )}
            ></Table>
          </Form>
        </div>
      </div>

      <div className="list-render">
        <div className="select-face">
          {faces?.map((item: Face) => (
            <span
              key={item.id}
              className={`face ${item.face === activeFace ? "active" : ""}`}
              onClick={() => setActiveFace(item.face)}
            >
              {item.name}
            </span>
          ))}
        </div>

        {dataStyle?.map((style: any) => {
          return (
            <div key={style.id} className="wrap">
              <div className="title-style-colors">
                <p>{`${style.name}`}</p>
              </div>
              <div className="list-item-render">
                {dataStyleColor?.map((data: any, index: any) => {
                  return (
                    <Fragment key={index}>
                      {style.id === data.style_id ? (
                        <div className="item-render">
                          {/* <Checkbox
                            key={`checkbox-item-${index}`}
                            name={`checkbox-item-${index}`}
                            checked={data?.status === 2 ? false : true}
                            onChange={(e) => {
                              dispatch(handleCheckboxStatus({ e, data }));
                            }}
                            className="select-item-render"
                          ></Checkbox> */}
                          <>
                            {getImageStyleColor(data.infos, activeFace)
                              ?.image ? (
                              <Upload
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(e) =>
                                  HandleChangeGenerate(e, {
                                    style_color_id: data.id,
                                    face: activeFace,
                                  })
                                }
                                fileList={[
                                  {
                                    uid: "-1",
                                    name: "image.png",
                                    url: getImageStyleColor(
                                      data.infos,
                                      activeFace
                                    )?.image,
                                  },
                                ]}
                              />
                            ) : (
                              <Upload
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(e) =>
                                  HandleChangeGenerate(e, {
                                    style_color_id: data.id,
                                    face: activeFace,
                                  })
                                }
                              >
                                {uploadButton}
                              </Upload>
                            )}
                          </>
                          <div className="name-item-render">
                            {dataColor.map((item: any) =>
                              item.id === data.color_id
                                ? `${style.name} - ${item.name}`
                                : ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {
        <div className="btn-save">
          <Button
            type="primary"
            onClick={() => {
              handleSaveAll();
            }}
            icon={<SaveOutlined />}
            size="large"
            style={{ marginTop: 30 }}
            disabled={
              !(itemGenerateChange.length > 0) &&
              listUpdateStyleColor.length === 0
            }
          >
            保存
          </Button>
        </div>
      }
    </Layout>
  );
};
