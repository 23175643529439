import patchDynamic from "../helper/pathDynamic";

const routes = {
  // ADMIN ROUTE
  // adminDasboard: '/admin',
  // products: '/admin/products',
  // listProduct: '/admin/products/list-product',
  // addProduct: '/admin/products/add-product',
  // styleColor: '/admin/products/:product_id/item/:item_id',
  // subStyleColor: '/admin/products/:product_id/item/:item_id/substyle/:substyle_id',
  // textStyleColor: '/admin/products/:product_id/item/:item_id/textstyle/:textstyle_id',
  // editProduct: '/admin/edit-product/:id',
  // setting: '/admin/products/:product_id/setting',
  // selectedProduct: 'admin/account/selected-products',

  adminDasboard: `/admin-${patchDynamic}`,
  tagSeo: `/admin-${patchDynamic}/tag-seo`,
  products: `/admin-${patchDynamic}/products`,
  listProduct: `/admin-${patchDynamic}/products/list-product`,
  addProduct: `/admin-${patchDynamic}/products/add-product`,
  styleColor: `/admin-${patchDynamic}/products/:product_id/item/:item_id`,
  subStyleColor: `/admin-${patchDynamic}/products/:product_id/item/:item_id/substyle/:substyle_id`,
  textStyleColor: `/admin-${patchDynamic}/products/:product_id/item/:item_id/textstyle/:style_id`,
  editProduct: `/admin-${patchDynamic}/edit-product/:id`,
  setting: `/admin-${patchDynamic}/products/:product_id/setting`,
  selectedProduct: `/admin-${patchDynamic}/account/selected-products`,

  // CUSTOMER ROUTE
  home: "/",
  termUse1: "/terms-of-use-1",
  termUse2: "/terms-of-use-2",
  chooseOption: "/choose-options",
  chooseProduct: "/choose-product",
  chooseStyles: "/choose-styles",
  chooseColors: "/choose-colors/:product_id",
  tempOrders: "/temp-orders",
  overView: "/overview/:id",
  previewProduct: "/preview-product/:id",
  paymentView: "/payment/:order_id",
  paymentSuccessCar: "/payment-success/:id",
  paymentSuccessShip: "/payment-success",
  paymentInfo: "payment-info/:id_payment",

  // PUBLIC ROUTE
  login: `/login-${patchDynamic}`,
  // otpConfirm: `/otp-confirm-${patchDynamic}`,
  register: "/register",
};

export default routes;
