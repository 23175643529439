import { ArrowUpOutlined } from "@ant-design/icons";
import handleBackTop from "../../helper/handleBackTop";

export default function FooterCustomer() {
  return (
    <div className="footer" style={{ height: "8rem" }}>
      <div className="link-footer">
        {/* <a href="#" target="_blank">
          COMPANY
        </a>
        <a href="#" target="_blank">
          CONTACT
        </a> */}
        <p style={{ color: "#fff", fontSize: "14px", textAlign: "center" }}>
          Copyright © 2023 Steal Leather Industry
        </p>
      </div>
      <div className="btn-back-top" onClick={handleBackTop}>
        <ArrowUpOutlined className="icon-arrow" />
        {/* <p>Page Top</p> */}
      </div>
    </div>
  );
}
