import React, { Fragment, useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  EditOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  // UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Layout,
  Input,
  Form,
  Upload,
  notification,
  Table,
  Space,
  Popconfirm,
  InputNumber,
  // Checkbox,
  // Spin,
} from "antd";
import {
  DataTypeColor,
  DataGenerateChange,
  DataTypeStyle,
} from "../../../types/styleColor";
import imageDefault from "../../../helper/imageDefault";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { UseConvertImage } from "../../../hooks/useConvertImage";
import {
  addStyle,
  getItemById,
  getStyleById,
  addRowSubStyle,
  changeSubStyleStatus,
  cancelSubStyle,
  deleteRowSubStyle,
  setRowSubStyleEdit,
  editSubStyle,
  setDataSubStyleColor,
  // handleCheckboxSubStyleStatus,
} from "../../../redux/services/styleColorSlice";
import { styleColorApi } from "../../../api/styleColorApi/styleColorApi";
import { getProductsById } from "../../../redux/services/productSlice";
import { Face } from "../../../types/faces";
import { facesApi } from "../../../api";
import { getLocalStorage } from "../../../utils/localStorage";
import overLoader from "../../../components/overLoader";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

export const SubStyleColor = () => {
  const role_user: any = getLocalStorage("role_user");
  const dispatch = useDispatch();
  const { product_id, item_id, substyle_id } = useParams();

  const { Content } = Layout;
  const [api, showPopup] = notification.useNotification();
  const [itemGenerateChange, setItemGenerateChange] = useState<
    DataGenerateChange[]
  >([]);
  const [colorChange, setColorChange] = useState("");
  const [formStyle] = Form.useForm();
  const [formColor] = Form.useForm();
  const [faces, setFaces] = useState<any>([]);
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [activeFace, setActiveFace] = useState<number>(faces[0]?.face);

  const {
    itemById,
    dataSubStyle,
    dataStyleById,
    dataColor,
    dataSubStyleColor,
    subStyleStatus,
    loading,
    idRowSubStyleEdit,
    listUpdateSubStyleColor,
  } = useSelector((state: any) => state.styleColorReducer);

  const [handleDataSubStyle, setHandleDataSubStyle] = useState<any>([]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
    </div>
  );

  const valueFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  // STYLE
  const onFinishSubStyle = async (values: any) => {
    const checkExistImage = values.image_base64?.[0];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleBase64 = checkExistImage
      ? await UseConvertImage(values.image_base64?.[0]?.originFileObj)
      : null;
    const newSubStyles = handleBase64
      ? {
          name: values.name,
          item_id: item_id,
          image_base64: handleBase64,
          price: role_user === 0 ? 0 : Number(values.price),
          parent_id: substyle_id,
        }
      : {
          name: values.name,
          item_id: item_id,
          // image_base64: '',
          price: role_user === 0 ? 0 : Number(values.price),
          parent_id: substyle_id,
        };

    try {
      if (subStyleStatus === "add") {
        const res = await dispatch(addStyle({ ...newSubStyles }));
        if (res.payload.status === "success") {
          dispatch(cancelSubStyle("add"));

          const newData = {
            name: res?.payload?.data?.name,
            image_base64: res?.payload?.data?.image,
            price: res?.payload?.data?.price,
            key: res?.payload?.data?.style_id,
          };

          dispatch(addRowSubStyle(newData));
          dispatch(getStyleById(substyle_id));
          alertSuccess("保存しました。");
          formStyle.resetFields();
        }
      } else {
        const res = await dispatch(
          editSubStyle({
            id: idRowSubStyleEdit,
            body: { ...newSubStyles },
          })
        );

        if (res.payload.status === "success") {
          alertSuccess("保存しました。");
          formStyle.resetFields();
          dispatch(getStyleById(substyle_id));
        }
      }
    } catch (err) {
      alertFail("追加に失敗しました。");
    }
  };

  const addRowSubStyles = async () => {
    const rowsInput: any = {
      key: (dataSubStyle?.length + 1).toString(),
      image_base64: imageDefault,
      name: "",
      price: 0,
      active: true,
      parent_id: item_id,
    };

    dispatch(addRowSubStyle(rowsInput));
    dispatch(changeSubStyleStatus("add"));
  };

  const changeImageSubStyle = async (e: any, idStyle: number) => {
    const checkExistImage = await e.fileList[0];
    setTimeout(() => {
      if (checkExistImage) {
        const tempData = handleDataSubStyle?.map((style: any) =>
          style.id === idStyle
            ? { ...style, image: checkExistImage?.thumbUrl }
            : style
        );
        setHandleDataSubStyle([...tempData]);
      } else {
        const tempData = handleDataSubStyle?.map((style: any) =>
          style.id === idStyle ? { ...style, image: "" } : style
        );
        setHandleDataSubStyle([...tempData]);
      }
    }, 300);
  };

  const deleteRowSubStyles = async (idDelete: any) => {
    try {
      const res = await dispatch(deleteRowSubStyle(idDelete));
      if (res.payload !== undefined) {
        alertSuccess("削除しました。");
        formStyle.resetFields();
      }
    } catch (err) {
      alertFail("削除に失敗しました。");
    }
  };

  // Change Generate
  const HandleChangeGenerate = async (e: any, data: any) => {
    // Handle Convert Iamge Base64
    const handleConvertImage = await UseConvertImage(
      e.fileList[0]?.originFileObj
    );

    // Check exist data change
    const checkValue = (data: any) =>
      itemGenerateChange.filter(
        (item: any) =>
          item.style_color_id === data.style_color_id && item.face === data.face
      );

    setTimeout(() => {
      // Check exist image
      if (e.fileList[0]) {
        //Check Data Change Duplicate
        if (checkValue(data).length > 0) {
          const handleDuplicate = itemGenerateChange.map((item: any) =>
            item.style_color_id === checkValue(data)[0].style_color_id &&
            item.face === checkValue(data)[0].face
              ? { ...checkValue(data)[0], image_base64: handleConvertImage }
              : item
          );
          // Update List Data
          const handleUpdateDataLoop = dataSubStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face
                        ? { ...i, image: handleConvertImage }
                        : i
                    ),
                  ],
                }
              : item
          );

          setItemGenerateChange([...handleDuplicate]);
          dispatch(setDataSubStyleColor([...handleUpdateDataLoop]));
        } else {
          setItemGenerateChange([
            ...itemGenerateChange,
            { ...data, image_base64: handleConvertImage },
          ]);
          const handleUpdateDataLoop = dataSubStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face
                        ? { ...i, image: handleConvertImage }
                        : i
                    ),
                  ],
                }
              : item
          );
          dispatch(setDataSubStyleColor([...handleUpdateDataLoop]));
        }
      } else {
        if (checkValue(data).length > 0) {
          const handleDataDelete = itemGenerateChange.map((item: any) =>
            item.style_color_id === data.style_color_id &&
            item.face === data.face
              ? { ...data, image_base64: "" }
              : item
          );
          const handleUpdateDataLoop = dataSubStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face ? { ...i, image: "" } : i
                    ),
                  ],
                }
              : item
          );
          setItemGenerateChange([...handleDataDelete]);
          dispatch(setDataSubStyleColor([...handleUpdateDataLoop]));
        } else {
          setItemGenerateChange([
            ...itemGenerateChange,
            { ...data, image_base64: "" },
          ]);
          const handleUpdateDataLoop = dataSubStyleColor.map((item: any) =>
            item.id === data.style_color_id
              ? {
                  ...item,
                  infos: [
                    ...item.infos.map((i: any) =>
                      i.face === data.face ? { ...i, image: "" } : i
                    ),
                  ],
                }
              : item
          );
          dispatch(setDataSubStyleColor([...handleUpdateDataLoop]));
        }
      }
    }, 300);
  };

  // SAVE
  const handleSaveAll = async () => {
    setStatusLoader(true);
    setItemGenerateChange([]);
    try {
      if (itemGenerateChange.length) {
        const resImage: any = await styleColorApi.updateImageStyleColor(
          itemGenerateChange
        );
        if (resImage.status === "success") {
          setTimeout(() => {
            setStatusLoader(false);
            alertSuccess("保存しました。");
          }, 500);
        }
      }
      if (listUpdateSubStyleColor.length) {
        const resStatus: any = await styleColorApi.updateStatusStyleColor(
          listUpdateSubStyleColor
        );
        if (resStatus.status === "success") {
          setTimeout(() => {
            setStatusLoader(false);
            alertSuccess("保存しました。");
          }, 500);
        }
      }
    } catch (err) {
      alertFail("保存に失敗しました。");
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    render: (text: any, record: any, index: any) => {
      // SUB STYLE

      if (dataIndex === "image") {
        const imageEdit = (
          <img
            src={record.image || record.image_base64 || imageDefault}
            width={40}
            style={{ margin: "0 auto" }}
            alt="Error"
          />
        );
        if (record.active) {
          return (
            <Form.Item
              name="image_base64"
              valuePropName=""
              getValueFromEvent={valueFile}
              style={{ width: 80 }}
            >
              {record.image ? (
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => {
                    return false;
                  }}
                  fileList={[
                    {
                      uid: "-1",
                      name: "image.png",
                      status: "done",
                      url: record.image,
                    },
                  ]}
                  onChange={(e) => changeImageSubStyle(e, record.id)}
                />
              ) : (
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(e) => changeImageSubStyle(e, record.id)}
                >
                  {uploadButton}
                </Upload>
              )}
            </Form.Item>
          );
        } else {
          return imageEdit ? imageEdit : imageDefault;
        }
      }

      if (dataIndex === "name") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.name}
            </span>
          );
        }
      }

      if (dataIndex === "price") {
        if (record.active) {
          return role_user === 0 ? (
            <Form.Item name="price">
              <InputNumber disabled type="number" min={0} />
            </Form.Item>
          ) : (
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <InputNumber type="number" min={0} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.price === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.price}
            </span>
          );
        }
      }

      if (dataIndex === "actions") {
        if (record.active && subStyleStatus === "add") {
          return (
            <Space>
              <Button
                danger
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelSubStyle(
                      subStyleStatus === "edit" ? "edit" : record.id
                    )
                  );
                  formStyle.resetFields();
                }}
              />
              <Button
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                danger
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(
                    cancelSubStyle(
                      subStyleStatus === "edit" ? "edit" : record.id
                    )
                  );
                  formStyle.resetFields();
                }}
              />
              <Button
                type="default"
                icon={<EditOutlined />}
                title="Edit"
                onClick={() => {
                  dispatch(changeSubStyleStatus("edit"));
                  dispatch(setRowSubStyleEdit(record.id));
                  formStyle.setFieldsValue({
                    key: index,
                    name: record.name,
                    price: record.price,
                  });
                }}
              />
              <Button
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
              <Popconfirm
                title="削除しますか？"
                okText="はい"
                cancelText="いいえ"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  deleteRowSubStyles(record.id);
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title="Delete"
                ></Button>
              </Popconfirm>
            </Space>
          );
        }
      }

      // COLOR

      if (dataIndex === "color_code") {
        if (record.active) {
          return (
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <span>{colorChange ? colorChange : "Please Select Color!"}</span>
              <Form.Item
                name="color_code"
                rules={[
                  {
                    required: true,
                    message: "データを入力して下さい。",
                  },
                ]}
              >
                <Input
                  type="color"
                  value={colorChange}
                  style={{ width: "80px", height: "35px" }}
                  onBlur={(e) => {
                    setColorChange(e.target.value);
                  }}
                />
              </Form.Item>
            </Space>
          );
        } else {
          return (
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <span>{record.color_code}</span>
              <div
                className="color-box"
                style={{ backgroundColor: record.color_code }}
              ></div>
            </Space>
          );
        }
      }

      if (dataIndex === "color_name") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color:
                  record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
              }}
            >
              {record.name}
            </span>
          );
        }
      }
    },
  });

  // Get Image From Style Color and Face Product
  const getImageSubStyleColor = (data: any, face: number) => {
    const temp = data?.filter((item: any) => item.face === face);
    return temp ? temp[0] : [];
  };

  // Get List Face
  const getListFace = async () => {
    try {
      const res: any = await facesApi.getListFace();
      if (res.status === "success") {
        setFaces(res.data);
        setActiveFace(res.data[0].face);
      }
    } catch (err) {}
  };

  const columnsSubStyle: ColumnsType<DataTypeStyle> = [
    {
      key: "sort",
    },
    {
      title: "順",
      dataIndex: "no",
      align: "center",
      render: (data: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: "20%",
      className: "hidden-row",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    {
      title: "画像",
      dataIndex: "image",
      width: "30%",
      align: "center",
      ...getColumnSearchProps("image"),
    },
    {
      title: "名前",
      dataIndex: "name",
      width: "25%",
      align: "center",
      ...getColumnSearchProps("name"),
    },
    {
      title: "価格",
      width: "20%",
      dataIndex: "price",
      align: "center",
      ...getColumnSearchProps("price"),
    },
    {
      title: "操作",
      dataIndex: "actions",
      align: "center",
      ...getColumnSearchProps("actions"),
    },
  ];

  const columnsColor: ColumnsType<DataTypeColor> = [
    {
      title: "順",
      dataIndex: "no",
      align: "center",
      key: "no",
      render: (data, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Key",
      dataIndex: "key",
      // width: '36%',
      className: "hidden-row",
      key: "key-table",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    {
      title: "カラーコード",
      dataIndex: "color_code",
      align: "center",
      // width: '36%',
      key: "color_code",
      ...getColumnSearchProps("color_code"),
    },
    {
      title: "名前",
      dataIndex: "color_name",
      // width: '20%',
      align: "center",
      key: "color_name",
      ...getColumnSearchProps("color_name"),
    },
  ];

  // Handle Drag Drop
  const Row = ({ children, ...props }: any) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      cursor: "move",
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  // Update Sub Style List After Moving
  const handleListSubStyleUpdate = async (dataUpdate: any) => {
    const sub_style_ids: any = [];
    dataUpdate.forEach((subStyle: any) => {
      sub_style_ids.push(subStyle.id);
    });
    try {
      const res: any = await styleColorApi.updateSubStyleIndex({
        item_id: itemById.id,
        style_ids: sub_style_ids.toString(),
      });

      if (res.status === "success") {
        alertSuccess("移動しました。");
      }
    } catch (err) {
      alertFail("移動に失敗しました。");
    }
  };

  // Handle Move Item
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setHandleDataSubStyle((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.id === active.id);
        const overIndex = previous.findIndex((i: any) => i.id === over?.id);
        handleListSubStyleUpdate(arrayMove(previous, activeIndex, overIndex));
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    setHandleDataSubStyle([...dataSubStyle]);
  }, [dataSubStyle]);

  useEffect(() => {
    dispatch(getItemById(item_id));
    dispatch(getProductsById(product_id));
    dispatch(getStyleById(substyle_id));
    // eslint-disable-next-line
  }, [item_id, product_id, substyle_id, dispatch]);

  useEffect(() => {
    getListFace();
    setItemGenerateChange([]);
  }, []);

  return (
    <Layout
      className="style-color min-height-layout"
      style={{ padding: "24px" }}
    >
      {showPopup}
      {statusLoader ? overLoader() : ""}
      <p className="title-view">{dataStyleById?.name}</p>
      <Breadcrumb
        style={{ margin: "16px 0" }}
        items={[{ title: itemById.name }, { title: dataStyleById?.name }]}
      ></Breadcrumb>
      <Content className="site-layout-background content-wrap">
        {/* Table Sub Styles */}
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={[
              ...handleDataSubStyle.map((item: any) => ({
                ...item,
                key: item.id,
              })),
            ].map((i: any) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="table-wrap form-upload-style">
              <Form form={formStyle} onFinish={onFinishSubStyle}>
                <Table
                  components={{ body: { row: Row } }}
                  rowKey="id"
                  loading={loading}
                  columns={columnsSubStyle}
                  dataSource={[
                    ...handleDataSubStyle.map((item: any) => ({
                      ...item,
                      key: item.id,
                    })),
                  ]}
                  bordered
                  className="text-center"
                  title={() => (
                    <p>
                      スタイル
                      <Button
                        type="primary"
                        onClick={addRowSubStyles}
                        icon={<PlusCircleOutlined />}
                      >
                        スタイルを追加
                      </Button>
                    </p>
                  )}
                ></Table>
              </Form>
            </div>
          </SortableContext>
        </DndContext>

        {/* Table Colors */}
        <div className="table-wrap">
          <Form form={formColor}>
            <Table
              rowKey="id"
              loading={loading}
              columns={columnsColor}
              dataSource={dataColor}
              bordered
              className="text-center"
              title={() => <p>カラー</p>}
            ></Table>
          </Form>
        </div>
      </Content>

      <Content className="list-render">
        <div className="select-face">
          {faces?.map((item: Face) => (
            <span
              key={item.id}
              className={`face ${item.face === activeFace ? "active" : ""}`}
              onClick={() => setActiveFace(item.face)}
            >
              {item.name}
            </span>
          ))}
        </div>

        {dataSubStyle?.map((style: any) => {
          return (
            <div key={style.id} className="wrap">
              <div className="title-style-colors">
                <p>{`${style.name}`}</p>
              </div>
              <div className="list-item-render">
                {dataSubStyleColor?.map((data: any, index: any) => {
                  return (
                    <Fragment key={index}>
                      {style.id === data.style_id ? (
                        <div className="item-render">
                          {/* <Checkbox
                            key={`checkbox-item-${index}`}
                            name={`checkbox-item-${index}`}
                            checked={data?.status === 2 ? false : true}
                            onChange={(e) => {
                              dispatch(handleCheckboxSubStyleStatus({ e, data }));
                            }}
                            className="select-item-render"
                          ></Checkbox> */}
                          <>
                            {getImageSubStyleColor(data.infos, activeFace)
                              ?.image ? (
                              <Upload
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(e) =>
                                  HandleChangeGenerate(e, {
                                    style_color_id: data.id,
                                    face: activeFace,
                                  })
                                }
                                fileList={[
                                  {
                                    uid: "-1",
                                    name: "image.png",
                                    url: getImageSubStyleColor(
                                      data.infos,
                                      activeFace
                                    )?.image,
                                  },
                                ]}
                              />
                            ) : (
                              <Upload
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(e) =>
                                  HandleChangeGenerate(e, {
                                    style_color_id: data.id,
                                    face: activeFace,
                                  })
                                }
                              >
                                {uploadButton}
                              </Upload>
                            )}
                          </>
                          <div className="name-item-render">
                            {dataColor.map((item: any) =>
                              item.id === data.color_id
                                ? `${style.name} - ${item.name}`
                                : ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Content>

      {
        <div className="btn-save">
          <Button
            type="primary"
            onClick={() => {
              handleSaveAll();
            }}
            icon={<SaveOutlined />}
            size="large"
            style={{ marginTop: 30 }}
            disabled={
              !(itemGenerateChange.length > 0) &&
              listUpdateSubStyleColor.length === 0
            }
          >
            保存
          </Button>
        </div>
      }
    </Layout>
  );
};
