import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Form,
  Upload,
  Spin,
  notification,
  InputNumber,
} from "antd";
import { productApi } from "../../../api";
import { getProducts } from "../../../redux/services/productSlice";
import overLoader from "../../../components/overLoader";
import patchDynamic from "../../../helper/pathDynamic";

export const ProductDulicateModal = ({ dataProductById, labelCol }: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [api, showPopup] = notification.useNotification();

  let navigate = useNavigate();

  // Alert
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const onFinish = async (values: any) => {
    setStatusLoader(true);
    const dataProductDuplicate = {
      product_id: dataProductById.id,
      name: values.name,
      price: values.price,
      image_base64:
        values?.image_base64?.length === 0
          ? undefined
          : values.image_base64?.[0]?.thumbUrl,
      description: values.description,
      shipping_costs: values.shipping_costs,
    };

    try {
      const res: any = await productApi.duplicateProduct(dataProductDuplicate);
      if (res.status === "success") {
        dispatch(getProducts());
        setStatusLoader(false);
        alertSuccess(res.data.message);
        setTimeout(() => {
          navigate(
            `/admin-${patchDynamic}/products/${res.data.product_id}/setting`
          );
        }, 1500);
      }
    } catch (err) {
      setStatusLoader(false);
      alertFail("エラーがあります。");
    }
  };

  const valueFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue({
      name: `${dataProductById?.name} コピー `,
      price: dataProductById?.price,
      description: dataProductById?.description,
      image_base64: [
        {
          url: dataProductById?.image,
        },
      ],
      shipping_costs: dataProductById?.shipping_costs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductById]);
  return (
    <>
      {showPopup}
      {statusLoader ? overLoader() : ""}
      <Form
        name="basic"
        labelCol={{ span: labelCol }}
        // wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        labelAlign={"left"}
        initialValues={{ remember: true }}
      >
        <Form.Item
          label="商品名"
          name="name"
          rules={[{ required: true, message: "商品名を入力してください。" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="価格"
          name="price"
          rules={[{ required: true, message: "価格を入力してください。" }]}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>

        <Form.Item
          name="image_base64"
          label="画像"
          valuePropName="fileList"
          getValueFromEvent={valueFile}
        >
          <Upload
            listType="picture"
            maxCount={1}
            iconRender={() => {
              return <Spin></Spin>;
            }}
            beforeUpload={() => {
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>画像をアップロード</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="説明"
          name="description"
          rules={[{ required: true, message: "説明を入力してください。" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="配送料"
          name="shipping_costs"
          rules={[{ required: true, message: "配送料" }]}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            保存
          </Button>
        </div>
      </Form>
    </>
  );
};
